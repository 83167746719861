import { _axiosapi } from '@/api/_axios';
function qrcode(params) {
    return _axiosapi({
        url: `/member/mine/generate/code`,
        method: "POST",
        data:{request:params},
    })
}
//拉起账户卡号及余额
export const cardNumberBalanceList = (params)=>{
    return _axiosapi({
        url:'/member/homepage/cardNumberBalanceList',
        method:'POST',
        data:{request:params}
    })
}
//查询核销记录
export const feeDetail = (params)=>{
    return _axiosapi({
        url:`/mall/pay/calculation/fee/detail`,
        method:'POST',
        data:{request:params}
    })
}

//测试接口
export const test = (params)=>{
    return _axiosapi({
        url:`/mall/pay/calculation/fee/test`,
        method:'POST',
        data:params
    })
}
//测试支付
export const pay_no = (params) =>{
    return _axiosapi({
        url:`/mall/pay/test?payNo=${params}`,
        method:'GET'
    })
}
//测试退款
export const pay_refund = (params) =>{
    return _axiosapi({
        url:`/mall/pay/refund/test?payNo=${params}`,
        method:'GET'
    })
}

export { qrcode ,}  