<template>
  <div class="qrcode">
    <top title="直付码"></top>
    <div class="sil" :style="due?'margin-bottom:344px':'margin-bottom:299px'"></div>
    <div class="box">
      <div class="box_top">
        <div class="df">
          <img src="@/assets/img/logo@3x.png" alt="" class="box_img">
          <div>向商家直付</div>
        </div>
        <div class="df" @click="$router.push({path:'/instructions'})">
          <div class="explain" >使用说明</div>
          <van-icon name="arrow" color="#888" />
        </div>
        <div class="abs_left"></div>
        <div class="abs_right"></div>
      </div>

      <div class="pic">
        <canvas id="barcode" class="qrcode_canvas"></canvas>
      </div>
      <div class="qrcode_bottom">
        <img :src="qrcodes" alt="">
      </div>
      <div class="refs" v-show="due"  @click="sendqrcode"><van-icon name="replay" color="#019CF4" size="16px" style="margin-right:4px" />刷新直付码</div>
    </div>
    <div class="pay_type" @click="show = true" v-if="firstList.length != 0" >
       <img v-if="firstList.insCompanyLogo" :src="firstList.insCompanyLogo[0].url" alt="" class="qrcode_img">
      <div class="pay_box">
        <div class="pay_account">{{firstList.insCompanyName}}({{firstList.cardNumber.slice(firstList.cardNumber.length-4,firstList.cardNumber.length)}}) <van-icon name="arrow-down" /></div>
        <span>优先使用此直付方式直付</span>
      </div>
    </div>
    <p>{{ cardNo }}</p>
    <!-- <input type="text" v-model="demo.cardNo" placeholder="卡号啊" disabled>
    <input type="text" v-model="demo.payNo" placeholder="直付流水号">
    <input type="text" v-model="demo.socialSecurityType" placeholder="社保类型">
    <div style="border:1px solid;padding:10px" @click="submit">冲</div>
    <hr/>
    <input type="text" v-model="payNo" placeholder="这是直付的">
    <div style="border:1px solid;padding:10px" @click="chong">点啊</div>
    <hr/>
    <input type="text" v-model="refundNo" placeholder="这是退款的">
    <div style="border:1px solid;padding:10px" @click="tuikuan">点啊</div> -->

    <van-popup
      v-model="show"
      position="bottom"
      style="max-height:282px;border-top-left-radius:8px;border-top-right-radius:8px"
    >
      <div class="popup_fixed">
        <div class="popup">
          <!-- <span @click="show = false">取消</span> -->
          <div >选择优先直付方式</div>
          <!-- <span @click="show = false">完成</span> -->
        </div>
        <div class="popup_text">如付款失败将尝试使用其他直付方式完成付款</div>
      </div>
      <div class="sli"></div>
      <div v-for="(item,index) in list" :key="index">
        <div class="popup_box" @click="onClick(item)">
           <img v-if="firstList.insCompanyLogo" :src="item.insCompanyLogo[0].url" alt="" class="qrcode_img">
          <div class="pay_box">
            <div class="popup_acc">
              <span>{{item.insCompanyName}}({{item.number}})</span>
              <div>剩余总余额¥{{item.balance}}</div>
            </div>
          </div>
          <van-icon name="success" color="#1a9ff2" icon-size="16px" v-if="item.isDefault == 1" />
        </div>
      </div>
    </van-popup>

  </div>
</template>
<script>
import { qrcode,cardNumberBalanceList ,feeDetail,test,} from "../../api/_api/qrcode";
import JsBarcode from 'jsbarcode'
import QRCode from 'qrcode'
export default {
  data() {
    return {
      cardNo: "",
      qrcode: "",
      params: {"uid": "","cardNo":""},
      show:false,
      memberId:'',
      due:false,
      list:[],
      firstList:[],
      time:'',
      demo:{
        cardNo:'',
        payNo:'',
        socialSecurityType:''
      },
      qrcodes: ''
      // payNo:'',
      // refundNo:''
    };
  },
  methods: {
    submit(){
      test(this.demo).then(res=>{
        if(res.code == 200){
          this.$toast(res.message)
        }
      })
    },
    //获取params
    param() {
      let list = JSON.parse(sessionStorage.getItem("detailinfo"));
      this.cardNo = list.cardNo;
      this.params = {
        "uid": this.$cookies.get("uid"),
        "cardNo": this.cardNo
      };
    },
    //选中卡
    onClick(item){
      this.list.forEach(val=>{
        val.isDefault = 0;
        if(val.cardNumber == item.cardNumber){
          val.isDefault = 1
        }
      })
      this.firstList = item;
      this.sendqrcode();
      this.show = false;
    },
    //二维码获取
    sendqrcode() {
      this.due = false;
      clearInterval(this.time);
      qrcode({memberId:this.firstList.memberId}).then((res) => {
        this.qrcode = "data:image/png;base64," + res.data;
        this.demo.cardNo = res.data;
        this.createBarcode(res.data);
        this.createQrcode(res.data)
        this.init(res.data);
      })
    },
    succeed() {
      this.$router.push("cancel");
    },
    //生成条形码
    createBarcode(val){
      JsBarcode('#barcode',val,{
        format: "CODE128", //
        displayValue: false, // 是否在条形码下方显示文字 如果压缩图片数字会变形，可设置false，用css样式调整数字的位置
        textPosition: "bottom" //设置文本的垂直位置
      })
    },
    createQrcode (val) {
      QRCode.toDataURL(val, {
        width: 250,
        height: 250,
        errorCorrectionLevel: 'H'
      }).then(url => {
        this.qrcodes = url
      })
    },
    //获取卡列表
    BalanceList(){
      return new Promise(resolve=>{
        this.$toast.loading({
          message:'加载中...',
          forbidClick:true
        })
        cardNumberBalanceList().then(res=>{
          this.$toast.clear();
          if(res.code == 200){
            res.data.forEach(item=>{
              item.number = item.cardNumber.slice(item.cardNumber.length -4,item.cardNumber)
              if(item.insCompanyLogo){
                item.insCompanyLogo = JSON.parse(item.insCompanyLogo)
              }
              if(item.isDefault == 1){
                this.firstList = item
              }
            })
            if(!this.firstList.cardNumber){
              this.firstList = res.data[0]
            }
            this.list = res.data;
            resolve(true)
          }
        })
      })
    },
    //轮询查询直付结果
    init(val){
      this.time = setInterval(()=>{
        feeDetail({cardNumber:val}).then(res=>{
          if(res.code == 200){
            if(res.data.status == 1){
              this.$router.push({path:'/cancel',query:{ciMoney:res.data.ciMoney,payMoney:res.data.payMoney}})
            }
            if(res.data.status == 100){
              clearInterval(this.time)
              this.due = true
              this.$toast({
                message:'卡号失效，请重新刷新条形码',
                duration:3000
              })
            }
          }
        })
      },5000)
    },
    //测试直付
    // chong(){
    //   pay_no(this.payNo).then(res=>{
    //     if(res.code == 200){
    //       this.$toast(res.message)
    //     }
    //   })
    // },
    // //测试退款
    // tuikuan(){
    //   pay_refund(this.refundNo).then(res=>{
    //     if(res.code == 200){
    //       this.$toast(res.message)
    //     }
    //   })
    // }
  },
  destroyed(){
    clearInterval(this.time)
  },
  mounted: function () {
    this.BalanceList().then(()=>{
        this.sendqrcode()
      })
    },
};
</script>

<style lang="scss" scoped>
.qrcode_bottom {
  width: 100%;
  text-align: center;
}
.qrcode_canvas{
  width:100% ;
  height:  100%;
}
.qrcode {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  .pic {
    width: vw(670);
    height: vw(230);
    border-radius: vw(20);
    box-sizing: border-box;
    margin: vw(20) vw(15) vw(0);
    img {
      width: 100%;
      height: 100%;
    }

  }
  .refs{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: vw(28);
    color:#019CF4 ;
    margin-bottom: vw(50);
  }
  p {
    font-size: vw(40);
    font-weight: 400;
    font-family: DIN-Medium;
    text-align: center;
  }
  .sil{
    width: 100%;
    height:vw(376);
    margin-bottom: vw(230);
    background-image: linear-gradient(135deg, #1D9CF2 0%, #0EAEF3 50%, #246ded 100%)
  }
  .box{
    // height: vw(500);
    width: vw(702);
    margin: 0 vw(24);
    background: #fff;
    position: absolute;
    top: vw(158);
    left: 0;
    border-radius: vw(12);
  }
  .box_top{
    position: relative;
    padding: vw(40) 0;
    margin: 0  vw(46);
    font-size: vw(32);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    border-bottom: 1px solid #f4f4f4;
  }
  .df{
    display: flex;
    align-items: center;
  }
  .box_img{
    width: vw(32);
    height: vw(32);
    margin-right: vw(16);
  }
  .abs_left{
    position: absolute;
    width: vw(32);
    height: vw(32);
    border-radius: 50%;
    top: 50%;
    left: vw(-48);
    background: #1a9ff2;
    transform: translate(-50%,-50%);
  }
  .abs_right{
    position: absolute;
    width: vw(32);
    height: vw(32);
    border-radius: 50%;
    top: 50%;
    right: vw(-78);
    background: #198df0;
    transform: translate(-50%,-50%);
  }
  .explain{
    margin-right: vw(16);
  }
  .pay_type{
    display: flex;
    align-items: center;
    margin: vw(4) vw(24);
    padding: vw(28) vw(24) ;
    background: #fff;
    font-size: vw(28);
  }
  .qrcode_img{
    width: vw(102);
    height: vw(102);
    border-radius: 50%;
    margin-right: vw(16);
  }
  .pay_box{
    width: 100%;
    color: #333;
  }
  .pay_account{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    margin-bottom: vw(4);
  }
}
.popup_fixed{
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 99;
}
.popup{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: vw(24) vw(24) 0;
  color: #666;
  font-size: vw(32);
  div{
    font-size: vw(36);
    color: #333;
  }
}
.popup_text{
  font-size: vw(28);
  color: #999;
  letter-spacing: -0.34px;
  margin-top: vw(6);
  margin-bottom: vw(24);
  text-align: center;
}
.sli{
  height: vw(124);
}
.popup_box{
  padding: vw(24);
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img{
    width: vw(92);
    height: vw(92);
    border-radius: 50%;
    margin-right: vw(16);
  }
  .popup_acc{
    // width: vw(546);
    font-size: vw(28);
    color: #999;
    span{
      color: #333;
      font-weight: 600;
    }

  }
}
</style>
